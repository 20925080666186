import type { Activity } from '~/types/activity'

// Reusable composable
const { api } = useApi()

const loading = ref({
  activity: false,
  moreActivity: false,
  count: false,
  error: false,
})
const currentPage = ref(2)
const perPage = 10
const totalPage = ref(0)
const totalActivities = ref(0)
const isActivityFinished = ref(false)

export default function useActivity() {
  // Reusable composables
  const { user } = storeToRefs(useUserStore())
  const { activities, count } = storeToRefs(useActivityStore())
  const { logError } = useSentry()

  const recentActivities = computed(() => {
    return activities.value?.slice(0, 5) as Activity[]
  })

  async function fetchActivities() {
    try {
      loading.value.activity = true
      loading.value.error = false
      const response = await api.activity.fetchActivities({
        page: 1,
        userId: user.value?.id,
      })
      if (response) {
        activities.value = response.data
        totalPage.value = response.totalPage
        totalActivities.value = response.total
      }
      loading.value.activity = false
    } catch (err) {
      loading.value.activity = false
      loading.value.error = true
      logError(err)
    }
  }
  async function fetchNextActivities() {
    if (currentPage.value <= totalPage.value) {
      try {
        loading.value.moreActivity = true
        const response = await api.activity.fetchActivities({
          page: currentPage.value,
          userId: user.value?.id,
        })
        loading.value.moreActivity = false
        if (response) {
          currentPage.value++
          activities.value?.push(...response.data)
        }
      } catch (err) {
        loading.value.moreActivity = false
        logError(err)
      }
    } else {
      isActivityFinished.value = true
    }
  }
  async function fetchActivityCount() {
    try {
      loading.value.count = true
      loading.value.error = false
      const response = await api.activity.fetchTotalActivitiesCount()
      if (response.success) {
        count.value = response.data
      }
      loading.value.count = false
    } catch (err) {
      loading.value.count = false
      loading.value.error = true
      logError(err)
    }
  }
  function activityTitle(activity: Activity) {
    if (activity.eventId) {
      return activity.eventId.title
    } else if (activity.inviteId) {
      return activity.inviteId.name
    } else {
      return activity.queueId?.groupId?.name || activity.groupId?.name
    }
  }

  return {
    perPage,
    totalActivities,
    loading,
    isActivityFinished,
    recentActivities,
    fetchActivities,
    fetchNextActivities,
    fetchActivityCount,
    activityTitle,
  }
}
